.search-seo-text-v1 {
	padding: 60px 0;
	background: var(--color-white);
	border-radius: 3px;
	scroll-margin-top: 120px;

	@media (max-width:767px) {
		padding: 20px;
	}

	.seo-feature-bl {
		text-align: center;
		background: #fff;
		height: 100%;
		padding: 30px;

		@media (max-width:992px) {
			border-bottom: 10px solid #f7efe2;
		}

		h2 {
			font-size: 22px;
		}
	}
}