.static-v1 {
	margin-top: var(--page-margin-top);

	.videoWrapper {
		position: relative;
		padding-bottom: 56.25%;
		/* 16:9 */
		height: 0;
	}


	.contact-map {
		height: 450px;
	}

	.videoWrapper iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.icon-block {
		position: relative;
		padding-left: 85px;
		margin-bottom: 30px;
		min-height: 60px;

		.fa {
			height: 64px;
			width: 64px;
			line-height: 64px;
			font-size: 30px;
			text-align: center;
			border-radius: 500px;
			position: absolute;
			left: 0;
			margin-left: 2px;
			background-color: var(--color-primary);
			color: var(--color-white);

		}
	}

	.feature-card {
		background: var(--color-white);
		padding: 45px 25px 15px 25px;
		text-align: center;
		height: 100%;

		.icon {
			position: absolute;
			top: -20px;
			left: 0;
			right: 0;
			margin: 0 auto;
			background: #2b8860;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;

			a {
				color: #fff;
				text-decoration: none;
			}
		}

		h3 {
			margin-bottom: 20px;

			a {
				color: #333;
				text-decoration: none;
				text-transform: uppercase;
			}
		}

		img {
			width: 100%;
			margin-bottom: 20px;
		}

		p {
			margin: 0;
		}
	}

	.img-bl {
		position: relative;

		.btn {
			position: absolute;
			bottom: 30px;
			left: 20px;
			color: #fff;
			background-color: #000;
			padding: 5px 10px;
			border-radius: 0;
			border: 0;
		}

		img {
			width: 100%;
		}
	}

	&.newsletter-v1 {
		.input-group-append {
			.btn {
				color: var(--color-white);
				background: var(--color-secondary);
			}
		}
	}
}