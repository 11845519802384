.about-us-v1 {
	margin-top: var(--page-margin-top);

	/*	.inner-banner {
		background-image: url(RESOURCE/img/bg-wir.jpg);
	}  */
	.member {
		/*max-height: 427px;*/
		margin-bottom: 20px;
		background-color: #fff;

		@media (max-width:1200px) {
			max-height: inherit;
			margin-bottom: 0;
		}
	}

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;

		.member-info {
			min-height: 181px;
			padding: 1em;
			justify-content: flex-start;
		}
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);


		.name {
			font-weight: 600;
		}

		.jobtitle,
		.address {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
		}

		.address {
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}


}